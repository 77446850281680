.div1 {
    display: flex;
    align-items: center;
}

.div2 {
    display: flex;
    align-items: center;
}

.div3 {
    display: flex;
    align-items: center;
}

.div4 {
    display: flex;
    align-items: center;
    margin: 0 auto;
}