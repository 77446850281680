body {
    background-color: #F8F9FA !important;
}

.upper-div {
    margin-top: 30px;
    border: 8px solid #612928;
    border-bottom: none;
    border-top-width: 2px;
}

.mid-div {
    background-color: white;
    height: 150vh;
}