body {
    margin: 0;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
}

.font-bold {
    font-weight: 900;
}

.rounded-sm {
    border-radius: 10px;
}

.rounded-md {
    border-radius: 20px;
}

.rounded-lg {
    border-radius: 30px;
}

.rounded-xl {
    border-radius: 40px;
}

.responsive-image {
    width: 100%;
    height: auto;
}